<template>
  <div>
    <div class="function_box">
      <div class="table_box_top flex_center">
        <div class="table_box_top_title">功能设置</div>
        <div class="flex_center_end_box">
          <div v-show="disdivictListLength" class="search_button button cancel_button1" @click="resetButton()">重 置</div>
          <div v-show="!disdivictListLength" class="search_button button cancel_button">重 置</div>
        </div>
      </div>
      <div v-if="preferenceShows" class="business_disdivict_box">
        <div class="table_box_top_title">商圈偏好设置</div>
        <div class="business_disdivict_box_add flex_center_end">
          <div @click="addPreferenxe()">
            <i class="iconfont icon-jiahao"></i>
          </div>
          <span>
            最多只能添加三个商圈偏好
          </span>
        </div>
        <ul>
          <li v-for="item in disdivictList" :key="item.index">
            <el-radio v-model="radio" :label="item.id" @change="radioChange()">设为默认偏好</el-radio>
            <div class="business_disdivict_div">
              <div class="business_disdivict_li_top flex_center">
                <div class="table_box_top_title table_box_top_title1">{{item.businessName}}</div>
                <div class="flex_center_end_box">
                  <el-tooltip class="item_color_money" effect="light" content="编辑" placement="top">
                    <i class="iconfont icon-bianji" @click="editPreference(item.id)"></i>
                  </el-tooltip>
                  <el-tooltip class="item_color_money" effect="light" content="删除" placement="top">
                    <i class="iconfont icon-shanchu" @click="deleteBusinessDistrict(item.id)"></i>
                  </el-tooltip>
                </div>
              </div>
              <div class="business_disdivict_li_bottom">
                <div>
                  <span>所属行业:</span>
                  <span class="over2">{{item.industryId}}</span>
                </div>
                <div>
                  <span>主要产品:</span>
                  <span class="over2">{{item.mainProducts}}</span>
                </div>
                <div>
                  <span>企业类型:</span>
                  <span class="over2">{{item.enterpriseTypeId}}</span>
                </div>
                <div>
                  <span>所属省份:</span>
                  <span class="over2">{{item.provinceId}}</span>
                </div>
                <div>
                  <span>主营业务收入:</span>
                  <span class="over2">{{item.operatingIncomeLower}} - {{item.operatingIncomeUpper}}</span>
                </div>
                <div>
                  <span>利润总额:</span>
                  <span class="over2">{{item.totalProfitLower}} - {{item.totalProfitUpper}}</span>
                </div>
                <div>
                  <span>总资产:</span>
                  <span class="over2">{{item.totalAssetLower}} - {{item.totalAssetUpper}}</span>
                </div>
                <div>
                  <span>净资产:</span>
                  <span class="over2">{{item.netAssetLower}} - {{item.netAssetUpper}}</span>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div v-if="!preferenceShows" class="business_disdivict_box business_special_box">
        <div class="table_box_top_title">风险偏好设置</div>
        <div class="business_disdivict_box_add flex_center_end">
          <div @click="addRiskPreference()">
            <i class="iconfont icon-jiahao"></i>
          </div>
          <span>
            最多只能添加三个风险偏好
          </span>
        </div>
        <ul>
          <li v-for="item in riskPreferenceList" :key="item.index">
            <el-radio v-model="radios" :label="item.id" @change="radioChange1()">设为默认偏好</el-radio>
            <div class="business_disdivict_div">
              <div class="business_disdivict_li_top flex_center">
                <div class="table_box_top_title table_box_top_title1">{{item.businessName}}</div>
                <div class="flex_center_end_box">
                  <el-tooltip class="item_color_money" effect="light" content="编辑" placement="top">
                    <i class="iconfont icon-bianji" @click="editRisk(item.id)"></i>
                  </el-tooltip>
                  <el-tooltip class="item_color_money" effect="light" content="删除" placement="top">
                    <i class="iconfont icon-shanchu" @click="deleteRisk(item.id)"></i>
                  </el-tooltip>
                </div>
              </div>
              <div class="business_disdivict_li_bottom">
                <div>
                  <span>投资规模(金额):</span>
                  <span class="over2">{{item.investmentScaleLower}} - {{item.investmentScaleUpper}}</span>
                </div>
                <div>
                  <span>投资回报(年化):</span>
                  <span class="over2">{{item.investmentReturnLower}} - {{item.investmentReturnUpper}}</span>
                </div>
                <div>
                  <span>投资周期(时间):</span>
                  <span class="over2">{{item.investmentCycleLower}} - {{item.investmentCycleUpper}}</span>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="business_disdivict_box business_special_box1">
        <div class="table_box_top flex_center">
          <div class="table_box_top_title">提醒设置</div>
          <div class="flex_center_end_box">
            <div class="search_button button" @click="preservation()">保 存</div>
          </div>
        </div>
        <el-form ref="form" :model="form">
          <el-form-item label="提醒方式:">
            <el-checkbox-group v-model="form.type" @change="checkedChange()">
              <el-checkbox v-for="item in typeList1" :key="item.index" :label="item"></el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div v-show="functionFill" class="function_fill"></div>
    <div v-show="contractShow" class="contract_allocation_box">
      <div class="table_box_top flex_center">
        <div class="table_box_top_title">合同配置</div>
        <div class="flex_center_end_box">
          <div class="search_button button" @click="addContract();dialogTit='添加合同文件'">添加合同模板</div>
        </div>
      </div>
      <div class="table_box_bottom">
        <el-table :data="contractTable" stripe border style="width: 100%">
          <el-table-column label="序号" type="index" :index="indexMethod" align="center" show-overflow-tooltip :resizable="false" :width="width120" />
          <el-table-column label="合同文件名称" prop="formworkName" align="center" show-overflow-tooltip :resizable="false" :width="nameWidth" />
          <el-table-column label="上传时间" prop="uploadDate" align="center" show-overflow-tooltip :resizable="false" :width="width180" />
          <el-table-column label="合同文件描述" prop="contractDesc" align="center" show-overflow-tooltip :resizable="false" />
          <el-table-column label="版本号" prop="version" align="center" :resizable="false" :width="versionWidth" />
          <el-table-column label="操 作" align="center" :resizable="false" width="130" fixed="right">
            <template slot-scope="scope">
              <div class="table_oper flex_center">
                <el-tooltip class="item_color" effect="light" content="编辑" placement="top">
                  <i class="iconfont icon-bianji" @click="editDialog(scope.row, scope.$index);dialogTit='编辑合同文件'"></i>
                </el-tooltip>
                <el-tooltip class="item_delete" effect="light" content="删除" placement="top">
                  <i class="iconfont icon-shanchu" @click="delDialog(scope.row);dialogTit='合同文件详情'"></i>
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <Page :totalFont="true" :total="faTotalPage" :currentPage="currentPage" @onPageChange="onPageChange"></Page>
      </div>
    </div>
    <!-- 删除弹框 -->
    <el-dialog :title="deletetPreference" :visible.sync="revokeFinancingDialog" center class="dialog_width500" :close-on-click-modal="false">
      <div class="revoke_confirm_box" v-if="deletetPreference == '删除商圈偏好'">
        是否删除此条商圈偏好？
      </div>
      <div class="revoke_confirm_box" v-else>
        是否删除此条风险偏好？
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="footer_button2" round @click="revokeFinancingDialog =false">取 消</el-button>
        <el-button class="footer_button1" type="primary" @click="deletePreference()">确 认</el-button>
      </span>
    </el-dialog>
    <!-- 添加商圈偏好 -->
    <el-dialog :key="firstForm2" title="推荐商圈偏好" :visible.sync="adddisdivictPreferenceDialog" center class="dialog_width500" :close-on-click-modal="false">
      <el-form ref="disdivictForms" :model="disdivictForms" class="risk_preference_form risk_preference_form_special" :rules="riskPreference1">
        <el-form-item label="风险偏好名称:" prop="businessName">
          <el-input v-model="disdivictForms.businessName" maxlength="50"></el-input>
        </el-form-item>
        <el-form-item label="所属行业大类:" class="place_cascader">
          <el-autocomplete placeholder="" v-model="disdivictForms.industry1" :fetch-suggestions="querySearch1" suffix-icon="iconfont icon-jiantou" @select="handleSelect1" @change="handleChange1">
          </el-autocomplete>
        </el-form-item>
        <el-form-item label="所属行业子类:" class="place_cascader">
          <el-autocomplete placeholder="" v-model="disdivictForms.industry" :fetch-suggestions="querySearch2" @select="handleSelect2" :disabled="smallAutocomplete" suffix-icon="iconfont icon-jiantou">
          </el-autocomplete>
        </el-form-item>
        <el-form-item label="主要产品:">
          <el-input v-model="disdivictForms.mainProducts" maxlength="50"></el-input>
        </el-form-item>
        <el-form-item label="企业类型:">
          <el-select v-model="disdivictForms.enterpriseType" placeholder="">
            <el-option v-for="item in  companyState" :key="item.index" :label="item.value" :value="item.codeState"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属省份:">
          <el-select v-model="disdivictForms.province" placeholder="">
            <el-option v-for="item in  addressList1" :key="item.index" :label="item.value" :value="item.codeState" />
          </el-select>
        </el-form-item>
        <el-form-item label="主营业务收入(元):" class="label_class_special error_class">
          <el-form-item :show-message="opRules" :error="'前者小于后者'">
            <el-input v-model="disdivictForms.operatingIncomeLower" @input="regNumber1" @change="IncomeLower" maxlength="12"></el-input>
          </el-form-item>
          <span></span>
          <el-form-item :show-message="opRules">
            <el-input v-model="disdivictForms.operatingIncomeUpper" @input="regNumber2" @change="IncomeLower" maxlength="12"></el-input>
          </el-form-item>
        </el-form-item>
        <el-form-item label="利润总额(元):" class="label_class_special error_class">
          <el-form-item :show-message="opRules1" :error="'前者小于后者'">
            <el-input v-model="disdivictForms.totalProfitLower" @input="regNumber3" @change="IncomeLower1" maxlength="12"></el-input>
          </el-form-item>
          <span></span>
          <el-form-item :show-message="opRules1">
            <el-input v-model="disdivictForms.totalProfitUpper" @input="regNumber4" @change="IncomeLower1" maxlength="12"></el-input>
          </el-form-item>
        </el-form-item>
        <el-form-item label="总资产(元):" class="label_class_special error_class">
          <el-form-item :show-message="opRules2" :error="'前者小于后者'">
            <el-input v-model="disdivictForms.totalAssetLower" @input="regNumber5" @change="IncomeLower2" maxlength="12"></el-input>
          </el-form-item>
          <span></span>
          <el-form-item :show-message="opRules2">
            <el-input v-model="disdivictForms.totalAssetUpper" @input="regNumber6" @change="IncomeLower2" maxlength="12"></el-input>
          </el-form-item>
        </el-form-item>
        <el-form-item label="净资产(元):" class="label_class_special error_class">
          <el-form-item :show-message="opRules3" :error="'前者小于后者'">
            <el-input v-model="disdivictForms.netAssetLower" @input="regNumber7" @change="IncomeLower3" maxlength="12"></el-input>
          </el-form-item>
          <span></span>
          <el-form-item :show-message="opRules3">
            <el-input v-model="disdivictForms.netAssetUpper" @input="regNumber8" @change="IncomeLower3" maxlength="12"></el-input>
          </el-form-item>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button class="footer_button2" round @click="adddisdivictPreferenceDialog =false">取 消</el-button>
        <el-button class="footer_button1" type="primary" @click="adddisdivictPreferenceSubmit()">提 交</el-button>
      </span>
    </el-dialog>
    <!-- 编辑商圈偏好 -->
    <el-dialog title="推荐商圈偏好" :visible.sync="disdivictPreferenceDialog" center class="dialog_width500" :close-on-click-modal="false">
      <el-form ref="disdivictForm" :model="disdivictForms" class="risk_preference_form risk_preference_form_special" :rules="riskPreference">
        <el-form-item label="风险偏好名称:" prop="businessName">
          <el-input v-model="disdivictForms.businessName" maxlength="50"></el-input>
        </el-form-item>
        <el-form-item label="所属行业大类:" class="place_cascader">
          <el-autocomplete placeholder="" v-model="disdivictForms.parentIndustry" :fetch-suggestions="querySearch1" suffix-icon="iconfont icon-jiantou" @select="handleSelect1" @change="handleChange">
          </el-autocomplete>
        </el-form-item>
        <el-form-item label="所属行业子类:" class="place_cascader">
          <el-autocomplete placeholder="" v-model="disdivictForms.industry" :fetch-suggestions="querySearch2" @select="handleSelect2" :disabled="smallAutocomplete" suffix-icon="iconfont icon-jiantou">
          </el-autocomplete>
        </el-form-item>
        <el-form-item label="主要产品:">
          <el-input v-model="disdivictForms.mainProducts" maxlength="50"></el-input>
        </el-form-item>
        <el-form-item label="企业类型:">
          <el-select v-model="disdivictForm.enterpriseType" placeholder="">
            <el-option v-for="item in  companyState" :key="item.index" :label="item.value" :value="item.codeState"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属省份:">
          <el-select v-model="disdivictForms.province" placeholder="">
            <el-option v-for="item in  addressList1" :key="item.index" :label="item.value" :value="item.codeState" />
          </el-select>
        </el-form-item>
        <el-form-item label="主营业务收入(元):" class="label_class_special error_class">
          <el-form-item :show-message="opRules" :error="'前者小于后者'">
            <el-input v-model="disdivictForms.operatingIncomeLower" @input="regNumber1" @change="IncomeLower" maxlength="12"></el-input>
          </el-form-item>
          <span></span>
          <el-form-item :show-message="opRules">
            <el-input v-model="disdivictForms.operatingIncomeUpper" @input="regNumber2" @change="IncomeLower" maxlength="12"></el-input>
          </el-form-item>
        </el-form-item>
        <el-form-item label="利润总额(元):" class="label_class_special error_class">
          <el-form-item :show-message="opRules1" :error="'前者小于后者'">
            <el-input v-model="disdivictForms.totalProfitLower" @input="regNumber3" @change="IncomeLower1" maxlength="12"></el-input>
          </el-form-item>
          <span></span>
          <el-form-item :show-message="opRules1">
            <el-input v-model="disdivictForms.totalProfitUpper" @input="regNumber4" @change="IncomeLower1" maxlength="12"></el-input>
          </el-form-item>
        </el-form-item>
        <el-form-item label="总资产(元):" class="label_class_special error_class">
          <el-form-item :show-message="opRules2" :error="'前者小于后者'">
            <el-input v-model="disdivictForms.totalAssetLower" @input="regNumber5" @change="IncomeLower2" maxlength="12"></el-input>
          </el-form-item>
          <span></span>
          <el-form-item :show-message="opRules2">
            <el-input v-model="disdivictForms.totalAssetUpper" @input="regNumber6" @change="IncomeLower2" maxlength="12"></el-input>
          </el-form-item>
        </el-form-item>
        <el-form-item label="净资产(元):" class="label_class_special error_class">
          <el-form-item :show-message="opRules3" :error="'前者小于后者'">
            <el-input v-model="disdivictForms.netAssetLower" @input="regNumber7" @change="IncomeLower3" maxlength="12"></el-input>
          </el-form-item>
          <span></span>
          <el-form-item :show-message="opRules3">
            <el-input v-model="disdivictForms.netAssetUpper" @input="regNumber8" @change="IncomeLower3" maxlength="12"></el-input>
          </el-form-item>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button class="footer_button2" round @click="disdivictPreferenceDialog =false">取 消</el-button>
        <el-button class="footer_button1" type="primary" @click="disdivictPreferenceSubmit()">提 交</el-button>
      </span>
    </el-dialog>
    <!-- 添加风险偏好 -->
    <el-dialog :key="firstForm1" title="风险偏好" :visible.sync="addriskPreferenceDialog" center class="dialog_width500 risk_dialog" :close-on-click-modal="false">
      <el-form ref="riskForms" :model="riskForms" class="risk_preference_form" :rules="riskPreference">
        <el-form-item label="风险偏好名称:" prop="businessName">
          <el-input v-model="riskForms.businessName" maxlength="50"></el-input>
        </el-form-item>
        <el-form-item label="投资规模(金额):" class="error_class">
          <el-form-item :show-message="isRules" :error="'前者小于后者'">
            <el-input v-model="riskForms.investmentScaleLower" @input="regNumber9" @change="scaleLower" maxlength="12"></el-input>
          </el-form-item>
          <span></span>
          <el-form-item :show-message="isRules">
            <el-input v-model="riskForms.investmentScaleUpper" @input="regNumber10" @change="scaleLower" maxlength="12"></el-input>
          </el-form-item>
        </el-form-item>
        <el-form-item label="投资回报(年化):" class="error_class">
          <el-form-item :show-message="isRules1" :error="'前者小于后者'">
            <el-input v-model="riskForms.investmentReturnLower" @input="regNumber11" @change="scaleLower1" maxlength="12"></el-input>
          </el-form-item>
          <span></span>
          <el-form-item :show-message="isRules1">
            <el-input v-model="riskForms.investmentReturnUpper" @input="regNumber12" @change="scaleLower1" maxlength="12"></el-input>
          </el-form-item>
        </el-form-item>
        <el-form-item label="投资周期(时间):" class="error_class">
          <el-form-item :show-message="isRules2" :error="'前者小于后者'">
            <el-input v-model="riskForms.investmentCycleLower" @input="regNumber13" @change="scaleLower2" maxlength="12"></el-input>
          </el-form-item>
          <span></span>
          <el-form-item :show-message="isRules2">
            <el-input v-model="riskForms.investmentCycleUpper" @input="regNumber14" @change="scaleLower2" maxlength="12"></el-input>
          </el-form-item>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button class="footer_button2" round @click="addriskPreferenceDialog =false">取 消</el-button>
        <el-button class="footer_button1" type="primary" @click="addriskPreferenceSubmit()">提 交</el-button>
      </span>
    </el-dialog>
    <!-- 编辑风险偏好 -->
    <el-dialog title="风险偏好" :visible.sync="riskPreferenceDialog" center class="dialog_width500 risk_dialog" :close-on-click-modal="false">
      <el-form ref="riskForm" :model="riskForms" class="risk_preference_form" :rules="riskPreference">
        <el-form-item label="风险偏好名称:" prop="businessName">
          <el-input v-model="riskForms.businessName"></el-input>
        </el-form-item>
        <el-form-item label="投资规模(金额):" class="error_class">
          <el-form-item :show-message="isRules" :error="'前者小于后者'">
            <el-input v-model="riskForms.investmentScaleLower" @input="regNumber9" @change="scaleLower" maxlength="12"></el-input>
          </el-form-item>
          <span></span>
          <el-form-item :show-message="isRules">
            <el-input v-model="riskForms.investmentScaleUpper" @input="regNumber10" @change="scaleLower" maxlength="12"></el-input>
          </el-form-item>
        </el-form-item>
        <el-form-item label="投资回报(年化):" class="error_class">
          <el-form-item :show-message="isRules1" :error="'前者小于后者'">
            <el-input v-model="riskForms.investmentReturnLower" @input="regNumber11" @change="scaleLower1" maxlength="12"></el-input>
          </el-form-item>
          <span></span>
          <el-form-item :show-message="isRules1">
            <el-input v-model="riskForms.investmentReturnUpper" @input="regNumber12" @change="scaleLower1" maxlength="12"></el-input>
          </el-form-item>
        </el-form-item>
        <el-form-item label="投资周期(时间):" class="error_class">
          <el-form-item :show-message="isRules2" :error="'前者小于后者'">
            <el-input v-model="riskForms.investmentCycleLower" @input="regNumber13" @change="scaleLower2" maxlength="12"></el-input>
          </el-form-item>
          <span></span>
          <el-form-item :show-message="isRules2">
            <el-input v-model="riskForms.investmentCycleUpper" @input="regNumber14" @change="scaleLower2" maxlength="12"></el-input>
          </el-form-item>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button class="footer_button2" round @click="riskPreferenceDialog =false">取 消</el-button>
        <el-button class="footer_button1" type="primary" @click="riskPreferenceSubmit()">提 交</el-button>
      </span>
    </el-dialog>
    <!-- 添加/编辑合同模板 -->
    <el-dialog :title="dialogTit" :visible.sync="contractDialog" center class="dialog_width500" :close-on-click-modal="false" :before-close="cancelForm">
      <div class="inner_common">
        <el-form :model="contractForm" ref="contractForm" :rules="contractRules">
          <el-form-item label="合同文件名称:" prop="formworkName">
            <el-input v-model="contractForm.formworkName"></el-input>
          </el-form-item>
          <el-form-item label="合作文件描述:" prop="contractDesc">
            <el-input type="textarea" :autosize="{ minRows: 3, maxRows: 6}" maxlength="200" show-word-limit v-model="contractForm.contractDesc">
            </el-input>
          </el-form-item>
          <el-form-item label="版本号:" prop="version">
            <el-input v-model="contractForm.version"></el-input>
          </el-form-item>
          <el-form-item label="上传文件:" class="change_width" prop="annex1" ref="uploadFormItem">
            <el-upload ref="uploadForm" v-model="contractForm.annex1" class="upload_demo" :http-request="handleUploadFile" action="fakeaction" accept='.pdf,.docx,.xlsx' :limit="1" :key='refe'>
              <div class="upload_box">
                <i class="el-icon-plus"></i>
              </div>
              <span slot="tip" class="el-upload__tip">支持word,excal,pdf格式，大小不超过15M</span>
            </el-upload>
            <div class="up_box" v-if="contractForm.annex1">
              <div class="contract_download_box" v-if="contractForm.annex1.name?contractForm.annex1.name.split('.').pop() == 'docx':contractForm.annex1.split('.').pop() == 'docx'">
                <img src="@/assets/img/system/supplier/word.png" alt="word">
              </div>
              <div class="contract_download_box" v-else-if="contractForm.annex1.name?contractForm.annex1.name.split('.').pop() == 'xlsx':contractForm.annex1.split('.').pop() == 'xlsx'">
                <i class="iconfont icon-Microsoft-Excel"></i>
              </div>
              <div class="contract_download_box" v-else>
                <img src="@/assets/img/pdf.png" alt="pdf">
              </div>
              <div class="delIcon" @click="contractForm.annex1=''">
                <i class="iconfont icon-yuyinguanbi"></i>
              </div>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button class="footer_button2" round @click="cancelForm()">取 消</el-button>
        <el-button class="footer_button1" type="primary" @click="submitForm('contractForm')">提 交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mixins1 } from "@/mixins/index";
import Page from '@/components/page'
import { uploads } from "@/api/api.js";
import { findPreferenceList, updatePreferenceList, findCodeInfoByKey, deletePreferenceList, findFirmInfoByKey, getCPCity, defaultPreference, updatePaymentSettings } from "@/api/investor.js";
import { findContConfigList, editContConfig, delConConfigInfoById } from "@/api/centerPerson.js";
export default {
  mixins: [mixins1],
  components: { Page },
  name: 'FunctionDefinition',
  data() {
    const valErrorMpreferenceName = (rule, val, cb) => {
      if (!val) {
        return cb(new Error('请输入风险偏好名称'))
      }
      return cb()
    }
    const valErrorMpreferenceName1 = (rule, val, cb) => {
      console.log(val)
      if (!val) {
        return cb(new Error('请输入风险偏好名称'))
      }
      return cb()
    }
    const valErrorAnnex1 = (rule, val, cb) => {
      if (!val) {
        return cb(new Error('请上传文件'))
      }
      return cb()
    }
    return {
      disdivictList: [
      ],
      riskPreferenceList: [
      ],
      dataList2: {
      },
      dataList3: {
        businessName: '',
        parentIndustry: '',
        industry: '',
        mainProducts: '',
        enterpriseType: '',
        province: '',
        operatingIncomeLower: '',
        operatingIncomeUpper: '',
        totalProfitLower: '',
        totalProfitUpper: '',
        totalAssetLower: '',
        totalAssetUpper: '',
        netAssetLower: '',
        netAssetUpper: ''
      },
      form: {
        type: [],
      },
      typeList1: ['站内信', '邮箱'],
      typeList2: ['站内信', '邮箱'],
      contractTable: [],
      options: [
      ],
      riskForm: {
        businessName: '',
        investmentScaleLower: '',
        investmentScaleUpper: '',
        investmentReturnLower: '',
        investmentReturnUpper: '',
        investmentCycleLower: '',
        investmentCycleUpper: ''
      },
      riskForms: {
        businessName: '',
        investmentScaleLower: '',
        investmentScaleUpper: '',
        investmentReturnLower: '',
        investmentReturnUpper: '',
        investmentCycleLower: '',
        investmentCycleUpper: ''
      },
      disdivictForm: {
        businessName: '',
        parentIndustry: '',
        industry: '',
        mainProducts: '',
        enterpriseType: '',
        province: '',
        operatingIncomeLower: '',
        operatingIncomeUpper: '',
        totalProfitLower: '',
        totalProfitUpper: '',
        totalAssetLower: '',
        totalAssetUpper: '',
        netAssetLower: '',
        netAssetUpper: ''
      },
      disdivictForms: {
        businessName: '',
        industry1: '',
        industry: '',
        mainProducts: '',
        enterpriseType: '',
        province: '',
        operatingIncomeLower: '',
        operatingIncomeUpper: '',
        totalProfitLower: '',
        totalProfitUpper: '',
        totalAssetLower: '',
        totalAssetUpper: '',
        netAssetLower: '',
        netAssetUpper: '',
      },
      disdivictForm1: {
        businessName1: '',
        industry1: '',
        industry: '',
        mainProducts: '',
        enterpriseType: '',
        province: '',
        operatingIncomeLower: '',
        operatingIncomeUpper: '',
        totalProfitLower: '',
        totalProfitUpper: '',
        totalAssetLower: '',
        totalAssetUpper: '',
        netAssetLower: '',
        netAssetUpper: '',
      },
      riskPreference: {
        businessName: [
          { required: true, validator: valErrorMpreferenceName, trigger: 'blur' }
        ]
      },
      riskPreference1: {
        businessName: [
          { required: true, validator: valErrorMpreferenceName1, trigger: 'blur' }
        ]
      },
      contractRules: {
        formworkName: [{ required: true, message: "请输入合同文件名称", trigger: "blur" }],
        contractDesc: [{ required: true, message: "请输入合同文件描述", trigger: "blur" }],
        version: [{ required: true, message: "请输入版本号", trigger: "blur" }],
        annex1: [{ required: true, validator: valErrorAnnex1, trigger: "blur" }]
      },
      contractForm: {
        formworkName: '',
        contractDesc: '',
        version: '',
        annex1: '',
        id: ''
      },
      deletetPreference: '',
      revokeFinancingDialog: false,
      riskPreferenceDialog: false,
      disdivictPreferenceDialog: false,
      contractDialog: false,
      addriskPreferenceDialog: false,
      adddisdivictPreferenceDialog: false,
      preferenceShows: false,
      contractShow: true,
      disdivictListLength: false,
      cascaderInput: false,
      functionFill: false,
      dialogTit: '添加合同文件',
      refe: 1,
      formDelete: { id: '' },
      disFormDelete: { id: '' },
      companyState: [],
      addressList1: [],
      formType: {
        'typeList1': '',
      },
      configObj: {
        formworkName: '',
        pageNo: 1,
        pageSize: 8,
      },
      faTotalPage: 0,
      currentPage: 1,//当前页
      fetchNum: 8,//当前分页数
      csics: '',
      industryCascader: '',
      typeChecked: '',
      radio: '',
      radios: '',
      radioId: '',
      dataname: [],
      dataname2: [],
      smallAutocomplete: true,
      nameList: [],
      nameLists: [],
      csicsName: '',
      csicLists: [],
      isRules: false,
      isRules1: false,
      isRules2: false,
      opRules: false,
      opRules1: false,
      opRules2: false,
      opRules3: false,
      firstForm1: 0,
      firstForm2: 0,
    }
  },
  // computed: {
  //   isRules() {
  //     return this.riskForms.investmentScaleLower && this.riskForms.investmentScaleUpper
  //   }
  // },
  created() {
    this.preferenceList()
    this.preferenceShow()
    this.preferenceList1()
    this.findContConfigList()
    this.resizeFn();
  },
  methods: {
    resizeFn() {
      if (document.body.clientWidth > 1664) {
        this.width120 = 70
        this.nameWidth = 200
        this.versionWidth = 140
      } else {
        this.width120 = 50
        this.nameWidth = 160
        this.versionWidth = 100
      }
    },
    regNumber1(val) {
      val = val.replace(/[^\d]/g, '')
      this.disdivictForms.operatingIncomeLower = val
    },
    regNumber2(val) {
      val = val.replace(/[^\d]/g, '')
      this.disdivictForms.operatingIncomeUpper = val
    },
    regNumber3(val) {
      val = val.replace(/[^\d]/g, '')
      this.disdivictForms.totalProfitLower = val
    },
    regNumber4(val) {
      val = val.replace(/[^\d]/g, '')
      this.disdivictForms.totalProfitUpper = val
    },
    regNumber5(val) {
      val = val.replace(/[^\d]/g, '')
      this.disdivictForms.totalAssetLower = val
    },
    regNumber6(val) {
      val = val.replace(/[^\d]/g, '')
      this.disdivictForms.totalAssetUpper = val
    },
    regNumber7(val) {
      val = val.replace(/[^\d]/g, '')
      this.disdivictForms.netAssetLower = val
    },
    regNumber8(val) {
      val = val.replace(/[^\d]/g, '')
      this.disdivictForms.netAssetUpper = val
    },
    regNumber9(val) {
      val = val.replace(/[^\d]/g, '')
      this.riskForms.investmentScaleLower = val
    },
    regNumber10(val) {
      val = val.replace(/[^\d]/g, '')
      this.riskForms.investmentScaleUpper = val
    },
    regNumber11(val) {
      val = val.replace(/[^\d]/g, '')
      this.riskForms.investmentReturnLower = val
    },
    regNumber12(val) {
      val = val.replace(/[^\d]/g, '')
      this.riskForms.investmentReturnUpper = val
    },
    regNumber13(val) {
      val = val.replace(/[^\d]/g, '')
      this.riskForms.investmentCycleLower = val
    },
    regNumber14(val) {
      val = val.replace(/[^\d]/g, '')
      this.riskForms.investmentCycleUpper = val
    },
    scaleLower() {
      this.isRules = false
      if (this.riskForms.investmentScaleLower && this.riskForms.investmentScaleUpper) {
        if (Number(this.riskForms.investmentScaleLower) > Number(this.riskForms.investmentScaleUpper)) {
          this.isRules = true
        } else {
          this.isRules = false
        }
      }
    },
    scaleLower1() {
      this.isRules1 = false
      if (this.riskForms.investmentReturnLower && this.riskForms.investmentReturnUpper) {
        if (Number(this.riskForms.investmentReturnLower) > Number(this.riskForms.investmentReturnUpper)) {
          this.isRules1 = true
        } else {
          this.isRules1 = false
        }
      }
    },
    scaleLower2() {
      this.isRules2 = false
      if (this.riskForms.investmentCycleLower && this.riskForms.investmentCycleUpper) {
        if (Number(this.riskForms.investmentCycleLower) > Number(this.riskForms.investmentCycleUpper)) {
          this.isRules2 = true
        } else {
          this.isRules2 = false
        }
      }
    },
    IncomeLower() {
      this.opRules = false
      if (this.disdivictForms.operatingIncomeLower && this.disdivictForms.operatingIncomeUpper) {
        if (Number(this.disdivictForms.operatingIncomeLower) > Number(this.disdivictForms.operatingIncomeUpper)) {
          this.opRules = true
        } else {
          this.opRules = false
        }
      }
    },
    IncomeLower1() {
      this.opRules1 = false
      if (this.disdivictForms.totalProfitLower && this.disdivictForms.totalProfitUpper) {
        if (Number(this.disdivictForms.totalProfitLower) > Number(this.disdivictForms.totalProfitUpper)) {
          this.opRules1 = true
        } else {
          this.opRule1s = false
        }
      }
    },
    IncomeLower2() {
      this.opRules2 = false
      if (this.disdivictForms.totalAssetLower && this.disdivictForms.totalAssetUpper) {
        if (Number(this.disdivictForms.totalAssetLower) > Number(this.disdivictForms.totalAssetUpper)) {
          this.opRules2 = true
        } else {
          this.opRules2 = false
        }
      }
    },
    IncomeLower3() {
      this.opRules3 = false
      if (this.disdivictForms.netAssetLower && this.disdivictForms.netAssetUpper) {
        if (Number(this.disdivictForms.netAssetLower) > Number(this.disdivictForms.netAssetUpper)) {
          this.opRules3 = true
        } else {
          this.opRules3 = false
        }
      }
    },
    handleChange() {
      this.disdivictForm.industry = ''
      if (!this.disdivictForm.parentIndustry) {
        this.smallAutocomplete = true
      }
    },
    handleChange1() {
      this.disdivictForms.industry = ''
      if (!this.disdivictForms.industry1) {
        this.smallAutocomplete = true
      }
    },
    async handleSelect1(item) {
      this.disdivictForms.industry1 = item.value
      this.disdivictForm.parentIndustry = item.value
      if (this.disdivictForms.industry1 || this.disdivictForm.parentIndustry) {
        this.smallAutocomplete = false
      }
      let data = await findFirmInfoByKey()
      data.data.forEach((item1, index) => {
        if (item.value == item1.firmName) {
          this.csicsName = item1.csic
        }
      })
      data = await findFirmInfoByKey('csic=' + this.csicsName)
      this.csicLists = data.data
      this.loadAll()
    },
    async getCompanyNameList() {
      let data = await findFirmInfoByKey()
      data.data.forEach((item, index) => {
        let obj = { value: item.firmName }
        this.nameList.push(obj)
      })
      this.dataname = this.nameList
    },
    querySearch1(queryString, cb) {
      var restaurants = this.dataname;
      var results = []
      if (restaurants) {
        results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
        // results = queryString ? restaurants : [];
      }
      
      cb(results);
    },
    async loadAll() {
      this.csicLists.forEach((item, index) => {
        let obj = { value: item.firmName }
        this.nameLists.push(obj)
      })
      this.dataname2 = this.nameLists
    },
    querySearch2(queryString, cb) {
      var restaurants = this.dataname2;
      var results = []
      if (restaurants) {
        results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
        // results = queryString ? restaurants : [];
      }
      
      cb(results);
    },
    handleSelect2(item) {
      this.disdivictForms.industry = item.value
      this.disdivictForm.industry = item.value
    },
    async radioChange() {
      let res = await findPreferenceList()
      this.disdivictList = res.data.businessPreferenceList
      this.disdivictList.forEach((item, index) => {
        if (this.radio == item.id) {
          this.radioId = item.id
        }
      })
      this.defaultPreferenceList()

    },
    async radioChange1() {
      let res = await findPreferenceList()
      this.riskPreferenceList = res.data.riskPreferenceList
      this.riskPreferenceList.forEach((item, index) => {
        if (this.radios == item.id) {
          this.radioId = item.id
        }
      })
      console.log(this.radioId)
      this.defaultPreferenceList1()

    },
    async defaultPreferenceList() {
      let res = await defaultPreference('Id=' + this.radioId)
      this.preferenceList1()
    },
    async defaultPreferenceList1() {
      let res = await defaultPreference('Id=' + this.radioId)
      this.preferenceList()
    },
    checkedChange() {
      let datas = localStorage.getItem('userInfo')
      datas = JSON.parse(datas)
      if (datas.roleId == '1') {
        if (this.form.type.length > 0 || this.disdivictList.length > 0 || (this.form.type.length > 0 && this.disdivictList.length > 0)) {
          this.disdivictListLength = true
        } else {
          this.disdivictListLength = false
        }
      }
      else if (datas.roleId == '2') {
        if (this.form.type.length > 0 || this.riskPreferenceList.length > 0 || (this.form.type.length > 0 && this.riskPreferenceList.length > 0)) {
          this.disdivictListLength = true
        } else {
          this.disdivictListLength = false
        }
      }
      else if (datas.roleId == '3') {
        if (this.form.type.length > 0 || this.disdivictList.length > 0 || (this.form.type.length > 0 && this.disdivictList.length > 0)) {
          this.disdivictListLength = true
        } else {
          this.disdivictListLength = false
        }
      }
    },
    preferenceShow() {
      let datas = localStorage.getItem('userInfo')
      datas = JSON.parse(datas)
      if (datas.roleId == '1') {
        this.preferenceShows = true
        this.contractShow = false
        this.functionFill = true
      }
      else if (datas.roleId == '2') {
        this.preferenceShows = false
        this.contractShow = true
        this.functionFill = false
      }
      else if (datas.roleId == '3') {
        this.preferenceShows = true
        this.contractShow = true
        this.functionFill = false
      }
    },
    async preferenceList(id) {
      console.log(this.form.type.length)
      let res = await findPreferenceList()
      this.riskPreferenceList = res.data.riskPreferenceList
      this.riskPreferenceList.forEach((item, index) => {
        if (id == item.id) {
          this.formDelete.id = item.id
        }
        if (item.flag == '1') {
          this.radios = item.id
        }
      })
      if ((res.data.riskPreferenceList.length > 0 && this.form.type.length > 0) || res.data.riskPreferenceList.length > 0 || res.data.paymentSettings > 0) {
        this.disdivictListLength = true
      }
      else {
        this.disdivictListLength = false
      }
      if (res.data.paymentSettings == 3) {
        this.$nextTick(() => {
          this.form.type = ['站内信', '邮箱']
        })
      }
      else if (res.data.paymentSettings == 2) {
        this.$nextTick(() => {
          this.form.type = ['邮箱']
        })
      }
      else if (res.data.paymentSettings == 1) {
        this.$nextTick(() => {
          this.form.type = ['站内信']
        })
      }
      else {
        this.$nextTick(() => {
          this.form.type = []
        })
      }
    },
    async preferenceList1(id) {
      let res = await findPreferenceList()
      this.disdivictList = res.data.businessPreferenceList
      this.disdivictList.forEach((item, index) => {
        if (id == item.id) {
          this.disFormDelete.id = item.id
        }
        if (item.flag == '1') {
          this.radio = item.id
        }
      })
      if ((res.data.businessPreferenceList.length > 0 && this.form.type.length > 0) || (res.data.businessPreferenceList.length > 0 || res.data.paymentSettings > 0)) {
        this.disdivictListLength = true
      }
      else {
        this.disdivictListLength = false
      }
      if (res.data.paymentSettings == 3) {
        this.$nextTick(() => {
          this.form.type = ['站内信', '邮箱']
        })
      }
      else if (res.data.paymentSettings == 2) {
        this.$nextTick(() => {
          this.form.type = ['邮箱']
        })
      }
      else if (res.data.paymentSettings == 1) {
        this.$nextTick(() => {
          this.form.type = ['站内信']
        })
      }
      else {
        this.$nextTick(() => {
          this.form.type = []
        })
      }
    },
    async updatePreference() {
      if (this.riskForms.investmentScaleLower == '') {
        this.riskForms.investmentScaleLower = null
      }
      if (this.riskForms.investmentScaleUpper == '') {
        this.riskForms.investmentScaleUpper = null
      }
      if (this.riskForms.investmentReturnLower == '') {
        this.riskForms.investmentReturnLower = null
      }
      if (this.riskForms.investmentReturnUpper == '') {
        this.riskForms.investmentReturnUpper = null
      }
      if (this.riskForms.investmentCycleLower == '') {
        this.riskForms.investmentCycleLower = null
      }
      if (this.riskForms.investmentCycleUpper == '') {
        this.riskForms.investmentCycleUpper = null
      }
      let PreferenceVO = {
        riskPreferenceList: []
      }
      PreferenceVO.update = '2'
      PreferenceVO.riskPreferenceList.push(this.riskForms)
      let res = await updatePreferenceList(PreferenceVO)
      this.preferenceList()
    },
    async updatePreference3() {
      if (this.disdivictForms.operatingIncomeLower == '') {
        this.disdivictForms.operatingIncomeLower = null
      }
      if (this.disdivictForms.operatingIncomeUpper == '') {
        this.disdivictForms.operatingIncomeUpper = null
      }
      if (this.disdivictForms.totalProfitLower == '') {
        this.disdivictForms.totalProfitLower = null
      }
      if (this.disdivictForms.totalProfitUpper == '') {
        this.disdivictForms.totalProfitUpper = null
      }
      if (this.disdivictForms.totalAssetLower == '') {
        this.disdivictForms.totalAssetLower = null
      }
      if (this.disdivictForms.totalAssetUpper == '') {
        this.disdivictForms.totalAssetUpper = null
      }
      if (this.disdivictForms.netAssetLower == '') {
        this.disdivictForms.operatingIncomeUpper = null
      }
      if (this.disdivictForms.operatingIncomeUpper == '') {
        this.disdivictForms.netAssetLower = null
      }
      let PreferenceVO = {
        businessPreferenceList: []
      }
      PreferenceVO.update = '2'
      PreferenceVO.businessPreferenceList.push(this.disdivictForms)
      let res = await updatePreferenceList(PreferenceVO)
      this.preferenceList1()
    },
    async updatePreference1() {
      let PreferenceVO = {
        riskPreferenceList: []
      }
      PreferenceVO.update = '3'
      PreferenceVO.riskPreferenceList.push(this.riskForms)
      let res = await updatePreferenceList(PreferenceVO)
      this.preferenceList()
    },
    async updatePreference2() {
      let PreferenceVO = {
        businessPreferenceList: []
      }
      PreferenceVO.update = '3'
      PreferenceVO.businessPreferenceList.push(this.disdivictForms)
      let res = await updatePreferenceList(PreferenceVO)
      this.preferenceList1()
    },
    async ficationList1() {
      let res = await findCodeInfoByKey('ms=' + 28)
      this.companyState = res.data
      res = await findCodeInfoByKey('ms=' + 23)
      this.addressList1 = res.data
    },
    // 删除商圈偏好
    deleteBusinessDistrict(id) {
      this.revokeFinancingDialog = true
      this.deletetPreference = '删除商圈偏好'
      this.preferenceList1(id)
    },
    // 删除风险偏好
    deleteRisk(id) {
      this.revokeFinancingDialog = true
      this.deletetPreference = '删除风险偏好'
      this.preferenceList(id)
    },
    // 确认删除偏好
    async deletePreference() {
      if (this.deletetPreference == '删除风险偏好') {
        let PreferenceVO = {
          riskPreferenceList: []
        }
        PreferenceVO.update = '1'
        PreferenceVO.riskPreferenceList.push(this.formDelete)
        let res = await updatePreferenceList(PreferenceVO)
        this.revokeFinancingDialog = false
        this.preferenceList()
      }
      else if (this.deletetPreference == '删除商圈偏好') {
        let PreferenceVO = {
          businessPreferenceList: []
        }
        PreferenceVO.update = '1'
        PreferenceVO.businessPreferenceList.push(this.disFormDelete)
        let res = await updatePreferenceList(PreferenceVO)
        this.revokeFinancingDialog = false
        this.preferenceList1()
      }
    },
    // 添加风险偏好
    addRiskPreference() {
      if (this.riskPreferenceList.length < 3) {
        this.addriskPreferenceDialog = true
      }
      else if (this.riskPreferenceList.length >= 3) {
        this.addriskPreferenceDialog = false
      }
      // this.$refs.riskForms.resetFields()
      this.riskForms = {}
      console.log(this.firstForm1)
      if (this.firstForm1 == 0) {
        console.log('ok')
        // this.$refs.riskForms.resetFields()
        this.riskForms = {}
      }
      this.firstForm1++
    },
    // 编辑提交风险偏好
    riskPreferenceSubmit() {
      this.$refs.riskForm.validate((valid) => {
        if (!this.isRules && !this.isRules1 && !this.isRules2) {
          if (valid) {
            this.updatePreference()
            this.riskPreferenceDialog = false
          }
        }
      })
    },
    // 添加提交风险偏好
    addriskPreferenceSubmit() {
      this.$refs.riskForms.validate((valid) => {
        if (!this.isRules && !this.isRules1 && !this.isRules2) {
          if (valid) {
            this.updatePreference1()
            this.addriskPreferenceDialog = false
          }
        }
      })
    },
    // 编辑风险偏好
    async editRisk(items) {
      let res = await findPreferenceList()
      this.dataList2 = res.data.riskPreferenceList
      res.data.riskPreferenceList.forEach((item, index) => {
        if (items == item.id) {
          this.riskForms = item
        }
      })
      this.riskPreferenceDialog = true
    },
    // 添加商圈偏好
    addPreferenxe() {
      this.ficationList1()
      this.dataname = this.getCompanyNameList()
      if (this.disdivictList.length < 3) {
        this.adddisdivictPreferenceDialog = true
      }
      else if (this.disdivictList.length >= 3) {
        this.adddisdivictPreferenceDialog = false
      }
      this.disdivictForms.industry1 = ''
      this.disdivictForms.industry = ''
      if (this.disdivictForms.industry1 == '') {
        this.smallAutocomplete = true
      }
      // this.$refs.disdivictForms.resetFields()
      this.disdivictForms = {}
      if (this.firstForm2 == 0) {
        this.disdivictForms = {}
      }
      this.firstForm2++
    },
    // 添加提交商圈偏好
    adddisdivictPreferenceSubmit() {
      this.$refs.disdivictForms.validate((valid) => {
        if (!this.opRules1 && !this.opRules && !this.opRules2 && !this.opRules3) {
          if (valid) {
            if ((this.disdivictForms.industry1 && this.disdivictForms.industry) || (!this.disdivictForms.industry1 && !this.disdivictForms.industry)) {
              this.updatePreference2()
              this.adddisdivictPreferenceDialog = false
            } else {
              this.$message({
                message: '需同时选择所属行业大类和所属行业子类!',
                type: 'warning'
              });
            }

          }
        }
      })

    },
    // 编辑商圈偏好
    async editPreference(items) {
      let res = await findPreferenceList()
      this.dataList3 = res.data.businessPreferenceList
      res.data.businessPreferenceList.forEach((item, index) => {
        if (items == item.id) {
          this.disdivictForms = item
          this.disdivictForms.industry = item.industryId
        }
      })
      this.dataname = this.getCompanyNameList()
      if (this.disdivictForm.parentIndustry) {
        this.smallAutocomplete = false
      }
      this.ficationList1()
      this.disdivictPreferenceDialog = true
      this.cascaderInput = true
      this.firstForm3++
    },
    cascaderInputs() {
      this.cascaderInput = false
    },
    // 编辑提交商圈偏好
    disdivictPreferenceSubmit() {
      this.$refs.disdivictForm.validate((valid) => {
        if (!this.opRules1 && !this.opRules && !this.opRules2 && !this.opRules3) {
          if (valid) {
            if ((this.disdivictForm.parentIndustry && this.disdivictForm.industry) || (!this.disdivictForm.parentIndustry && !this.disdivictForm.industry)) {
              this.updatePreference3()
              this.disdivictPreferenceDialog = false
            } else {
              this.$message({
                message: '需同时选择所属行业大类和所属行业子类!',
                type: 'warning'
              });
            }
          }
        }
      })
    },
    // 添加合同模板
    addContract() {
      this.contractDialog = true
      this.$nextTick(() => {
        this.$refs.contractForm.resetFields()
      })
    },
    // 取消合同模板
    cancelForm() {
      this.contractDialog = false
      this.$refs.contractForm.resetFields()
    },
    // 编辑合同
    async editContConfig() {
      let form = new FormData();
      form.append('files', this.contractForm.annex1)
      if (this.contractForm.id == undefined) {
        this.contractForm.id = ''
      }
      let res = await editContConfig(this.contractForm.formworkName, this.contractForm.version
        , this.contractForm.id, this.contractForm.contractDesc, form)
      if (res && res.code == 200) {
        this.configObj.formworkName = ''
        this.configObj.pageNo = 1
        this.configObj.pageSize = 8
        this.findContConfigList()
        this.contractDialog = false
        // this.$nextTick(() => {
        //   if (this.$refs.contractForm) {
        //     this.$refs.contractForm.resetFields()
        //   }
        // })
        // this.$refs.contractForm.clearValidate()
        this.contractForm = {
          formworkName: '',
          contractDesc: '',
          version: '',
          annex1: '',
          // id: ''
        }
      }
      else {
        this.$message.error(res.msg);
      }
    },
    //获取合同文件
    async findContConfigList() {
      let res = await findContConfigList('formworkName=' + this.configObj.formworkName + '&pageNo=' + this.configObj.pageNo + '&pageSize=' + this.configObj.pageSize)
      if (res && res.code == 200) {
        let obj = res.data
        this.configObj = obj
        this.contractTable = obj.records
        this.faTotalPage = obj.total
      }
      else {
        this.$message.error(res.msg);
      }
    },
    //编辑合同模板
    editDialog(row, index) {
      this.contractForm = {}
      this.contractDialog = true
      this.contractForm = JSON.parse(JSON.stringify(row))
    },
    //提交合同模板
    submitForm() {
      this.$refs["contractForm"].validate((valid) => {
        if (valid) {
          this.editContConfig()
        } else {
          return false;
        }
      });
    },
    //删除一条
    async delDialog(row, index) {
      let res = await delConConfigInfoById("Id=" + row.id)
      if (res && res.code == 200) {
        this.configObj.formworkName = ''
        this.configObj.pageNo = 1
        this.configObj.pageSize = 8
        this.findContConfigList()
        this.$message.success(res.msg);
      }
      else {
        this.$message.error(res.msg);
      }
    },
    //父分页方法
    onPageChange(event) {
      this.configObj.formworkName = ''
      this.configObj.pageNo = event
      this.configObj.pageSize = 8
      this.currentPage = event
      this.findContConfigList()
    },
    //序号Index 翻页递增  currentPage 当前页,  当前页显示条数
    indexMethod(index) {
      return (this.currentPage - 1) * this.fetchNum + index + 1;
    },
    // 上传合同
    handleUploadFile(params) {
      const file = params.file
      let form = new FormData();
      form.append("files", file);
      uploads(form).then(res => {
        this.contractForm.annex1 = res[0]
        this.refe++
        this.$refs.contractForm.clearValidate('annex1')
      })
    },
    // 重置
    clear() {
      this.$nextTick(() => {
        if (this.$refs.disdivictForms) {
          this.$refs.disdivictForms.resetFields();
        }
      })
    },
    async resetButton() {
      let res = await deletePreferenceList()
      // this.form.type = []
      location.reload()
    },
    // 保存
    async preservation() {
      if (this.form.type.length == 0) {
        this.typeChecked = 0
      }
      else if (this.form.type.length == 1 && this.form.type[0] == '站内信') {
        this.typeChecked = 1
      }
      else if (this.form.type.length == 1 && this.form.type[0] == '邮箱') {
        this.typeChecked = 2
      }
      else if (this.form.type.length == 2) {
        this.typeChecked = 3
      }
      let PreferenceVO = {
        paymentSettings: ''
      }
      PreferenceVO.paymentSettings = this.typeChecked
      let res = await updatePaymentSettings(PreferenceVO)
    }
  }
}
</script>


<style scoped>
  ::v-deep .risk_dialog .el-form-item__label {
    min-width: 107px;
  }
  ::v-deep .error_class .el-form-item.is-error .el-input__inner {
    border-color: #e1e7ea;
  }
  ::v-deep .place_cascader .icon-jiantou {
    color: #92a2bc;
    font-size: 18px;
  }
  ::v-deep .place_cascader .el-input__suffix {
    right: 6px !important;
  }
  ::v-deep .el-radio {
    width: 440px;
    margin-bottom: 10px;
    margin-right: 50px;
  }
  ::v-deep .el-radio:last-child {
    margin-right: 0;
  }
  ::v-deep .label_class_special .el-form-item__label {
    min-width: 121px !important;
  }

  ::v-deep .el-cascader .el-input .el-input__inner:hover {
    border: 1px solid #e1e7ea;
  }

  ::v-deep .place_cascader .el-cascader .el-input .el-input__inner::placeholder {
    visibility: hidden;
  }

  ::v-deep .el-cascader .el-input .el-input__inner::placeholder {
    color: #606266;
  }

  ::v-deep .el-cascader .el-input.is-focus .el-input__inner {
    border-color: #e1e7ea;
  }

  ::v-deep .el-cascader:not(.is-disabled):hover .el-input__inner {
    border: 1px solid #e1e7ea;
  }

  ::v-deep .el-cascader {
    width: 100%;
  }
  .upload_demo /deep/ .el-upload-list__item-name,
  ::v-deep .el-upload-list__item-name {
    display: none;
  }

  ::v-deep .el-upload-list {
    width: 41px;
  }

  .icon-Microsoft-Excel {
    font-size: 30px;
    color: #107b0f;
  }

  .upload_demo >>> .el-upload__tip {
    right: 32px;
  }

  .upload_box {
    width: 60px;
    height: 60px;
    margin-right: 6px;
    background: #f7fafc;
    border-radius: 4px;
    border: 1px solid #e1e7ea;
  }

  .upload_box i {
    font-size: 20px;
    line-height: 60px;
    color: #7c969b;
  }

  .upload_box i:focus {
    color: #7c969b;
  }

  .up_box {
    position: relative;
  }

  .delIcon {
    width: 16px;
    line-height: 15px;
    height: 16px;
    border-radius: 50%;
    text-align: center;
    background: #fb6476;
    position: absolute;
    top: -6px;
    left: 50px;
    cursor: pointer;
  }

  .delIcon i {
    display: inline-block;
    font-size: 12px;
    color: #ffffff;
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }

  .function_box {
    width: 100%;
    height: auto;
    padding: 30px 20px 20px 20px;
    background: #ffffff;
    box-shadow: 0px 4px 10px 0px rgba(12, 79, 71, 0.15);
    border-radius: 14px;
  }

  .cancel_button {
    background: #7c969b;
    cursor: not-allowed;
  }

  .cancel_button1 {
    background: #339c9b;
  }

  .business_disdivict_box {
    width: 100%;
    height: 575px;
    margin-bottom: 20px;
    padding: 20px 20px 40px 20px;
    background: #edf2f5;
    border-radius: 10px;
  }

  .business_special_box {
    height: 405px;
  }

  .business_special_box1 {
    height: 126px;
  }

  .business_disdivict_box_add {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .business_disdivict_box_add div:first-child {
    width: 40px;
    height: 40px;
    line-height: 38px;
    margin-right: 10px;
    background: #f7fafc;
    border-radius: 4px;
    border: 1px solid #e1e7ea;
    text-align: center;
    cursor: pointer;
  }

  .business_disdivict_box_add i {
    width: 20px;
    height: 20px;
    color: #7c969b;
  }

  .business_disdivict_box_add span {
    font-size: 12px;
    color: #92a2bc;
  }

  .business_disdivict_box li {
    float: left;
    width: 440px;
    height: auto;
    margin-right: 50px;
  }

  .business_disdivict_box li:last-child {
    margin-right: 0;
  }

  .business_disdivict_div {
    background: #ffffff;
    box-shadow: 0px 6px 9px 0px rgba(12, 79, 71, 0.16);
    border-radius: 6px;
  }

  .business_disdivict_li_top {
    width: 100%;
    height: 44px;
    margin-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    background: #339c9b;
    border-radius: 10px 10px 0px 0px;
  }

  .table_box_top_title1 {
    color: #ffffff;
  }

  .item_color_money {
    color: #ffffff;
  }

  .iconfont.el-tooltip:nth-child(n) {
    cursor: pointer;
    margin-right: 20px;
  }

  .business_disdivict_li_bottom {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
  }

  .business_disdivict_li_bottom div {
    width: 100%;
    margin-bottom: 14px;
    font-size: 14px;
  }

  .business_disdivict_li_bottom div:last-child {
    margin-bottom: 0;
  }

  .over2 {
    float: right;
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .business_special_box1 >>> .el-checkbox {
    margin-right: 40px;
  }

  .business_special_box1
    >>> .el-checkbox__input.is-checked
    + .el-checkbox__label {
    color: #162747;
  }

  .business_special_box1 >>> .el-form-item__content {
    line-height: 38px;
  }

  .business_special_box1 >>> .el-form-item {
    float: left;
    width: 30%;
  }

  ::v-deep .el-checkbox__inner {
    width: 24px;
    height: 24px;
    border-radius: 4px;
    border: 1px solid #e1e7ea;
  }

  ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
    border: 1px solid #e1e7ea !important;
  }

  .function_fill {
    height: 120px;
  }

  /* 合同配置 */
  .contract_allocation_box {
    width: 100%;
    height: auto;
    margin-top: 40px;
    margin-bottom: 120px;
    padding: 20px 20px 20px 20px;
    background: #ffffff;
    box-shadow: 0px 4px 10px 0px rgba(12, 79, 71, 0.15);
    border-radius: 14px;
  }

  .risk_preference_form span {
    display: inline-block;
    width: 12px;
    height: 1px;
    margin-bottom: 15px;
    margin-left: 4px;
    margin-right: 4px;
    background: #92a2bc;
  }

  .risk_preference_form >>> .el-form-item__content {
    display: flex;
    align-items: center;
  }

  .inner_common >>> .el-upload-list .el-icon-close {
    display: none;
  }

  .contract_download_box {
    width: 60px;
    height: 60px;
    margin-bottom: 5px;
    margin-right: 14px;
    padding-left: 15px;
    padding-top: 15px;
    background: #f7fafc;
    border-radius: 4px;
    border: 1px solid #e1e7ea;
  }

  .contract_download_box img {
    width: 30px;
    height: 30px;
  }

  ::v-deep .el-checkbox__inner::after {
    width: 7px;
    height: 12px;
    top: 2px;
  }

  @media screen and (max-width: 1664px) {
    /* ::v-deep .risk_dialog .el-form-item__label {
                                                                                                                                                                                                                                                                  min-width: 107px;
                                                                                                                                                                                                                                                                } */
    ::v-deep .place_cascader .icon-jiantou {
      color: #92a2bc;
      font-size: 15px;
    }
    ::v-deep .place_cascader .el-input__suffix {
      right: 10px !important;
    }
    ::v-deep .el-radio {
      width: 293px;
      margin-bottom: 6px;
      margin-right: 33px;
    }
    ::v-deep .label_class_special .el-form-item__label {
      min-width: 102px !important;
    }
    .risk_preference_form >>> .el-form-item__content {
      height: 26px;
    }

    .icon-Microsoft-Excel {
      font-size: 20px;
    }

    .upload_demo >>> .el-upload__tip {
      right: -30px;
      left: 20px;
      top: 0;
    }
    .upload_box {
      width: 40px;
      height: 40px;
      margin-right: 2px;
      border-radius: 4px;
    }

    .upload_box i {
      font-size: 14px;
      line-height: 40px;
    }

    .upload_list li {
      float: left;
      width: 40px;
      height: 40px;
      margin-right: 8px;
      border-radius: 3px;
    }

    .up_box {
      position: relative;
    }

    .delIcon {
      width: 13px;
      line-height: 12px;
      height: 13px;
      left: 32px;
    }

    .delIcon i {
      font-size: 8px;
    }

    .function_box {
      width: 100%;
      height: auto;
      padding: 20px 13px 13px 13px;
      background: #ffffff;
      box-shadow: 0px 2px 7px 0px rgba(12, 79, 71, 0.15);
      border-radius: 10px;
    }

    .business_disdivict_box {
      width: 100%;
      height: 406px;
      margin-bottom: 13px;
      padding: 13px 13px 26px 13px;
      background: #edf2f5;
      border-radius: 6px;
    }

    .business_special_box {
      height: 281px;
    }

    .business_special_box1 {
      height: 84px;
    }

    .business_disdivict_box_add {
      margin-top: 20px;
      margin-bottom: 20px;
    }

    .business_disdivict_box_add div:first-child {
      width: 26px;
      height: 26px;
      line-height: 24px;
      margin-right: 6px;
      background: #f7fafc;
      border-radius: 4px;
      border: 1px solid #e1e7ea;
      text-align: center;
      cursor: pointer;
    }

    .business_disdivict_box_add i {
      width: 13px;
      height: 13px;
      line-height: 13px;
      color: #7c969b;
    }

    .business_disdivict_box_add span {
      margin-left: -24px;
      font-size: 12px;
      color: #92a2bc;
      transform: scale(0.7);
    }

    .business_disdivict_box li {
      width: 293px;
      height: auto;
      margin-right: 33px;
    }

    .business_disdivict_box li:last-child {
      margin-right: 0;
    }

    .business_disdivict_div {
      background: #ffffff;
      box-shadow: 0px 6px 9px 0px rgba(12, 79, 71, 0.16);
      border-radius: 6px;
    }

    .business_disdivict_li_top {
      width: 100%;
      height: 29px;
      margin-bottom: 13px;
      padding-left: 13px;
      padding-right: 13px;
      background: #339c9b;
      border-radius: 6px 6px 0px 0px;
    }

    .business_disdivict_li_bottom {
      padding-left: 13px;
      padding-right: 13px;
      padding-bottom: 13px;
    }

    .iconfont.el-tooltip:nth-child(n) {
      cursor: pointer;
      margin-right: 13px;
    }

    .business_disdivict_li_bottom div {
      width: 100%;
      margin-bottom: 9px;
      font-size: 12px;
    }

    .over2 {
      float: right;
      max-width: 166px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .business_special_box1 >>> .el-form-item__content {
      line-height: 24px;
    }

    .business_special_box1 >>> .el-checkbox {
      margin-right: 26px;
    }

    ::v-deep .el-checkbox__inner {
      width: 16px;
      height: 16px;
      border-radius: 4px;
      border: 1px solid #e1e7ea;
    }

    .function_fill {
      height: 80px;
    }

    /* 合同配置 */
    .contract_allocation_box {
      width: 100%;
      height: auto;
      margin-top: 26px;
      margin-bottom: 80px;
      padding: 13px 13px 13px 13px;
      background: #ffffff;
      box-shadow: 0px 4px 10px 0px rgba(12, 79, 71, 0.15);
      border-radius: 14px;
    }

    .risk_preference_form span {
      display: inline-block;
      width: 8px;
      height: 1px;
      margin-left: 2px;
      margin-right: 2px;
      background: #92a2bc;
    }

    .risk_preference_form >>> .el-form-item__label {
      min-width: 91px;
    }

    .risk_preference_form_special >>> .el-form-item__label {
      min-width: 83px;
    }

    .contract_download_box {
      width: 40px;
      height: 40px;
      margin-top: 12px;
      margin-bottom: 3px;
      margin-right: 9px;
      padding-left: 9px;
      padding-top: 9px;
      border-radius: 4px;
    }

    .contract_download_box img {
      width: 20px;
      height: 20px;
    }

    ::v-deep .el-checkbox .el-checkbox__label {
      font-size: 12px;
    }

    ::v-deep .el-checkbox__inner::after {
      width: 3px;
      height: 7px;
      top: 2px;
      left: 5px;
    }

    ::v-deep .el-cascader .el-input .el-icon-arrow-down {
      width: 20px;
      font-size: 12px;
      color: #92a2bc;
    }

    ::v-deep .el-cascader-node {
      padding-left: 13px !important;
      padding-right: 20px !important;
    }
  }
</style>